import StarFilled from "./images/star-filled.svg";
import StarHalf from "./images/star-half.svg";
import StarEmpty from "./images/star-empty.svg";
import { NUMBER } from "../../../constants/app-constants";

export const STARS = {
    HALF: "half",
    FILLED: "filled",
    EMPTY: "empty"
};

export const STARS_MAPPING = {
    [STARS.HALF]: StarHalf,
    [STARS.FILLED]: StarFilled,
    [STARS.EMPTY]: StarEmpty
};

export const SLIDER_SETTINGS = {
    slidesToShow: NUMBER.ONE_POINT_THREE,
    slidesToScroll: NUMBER.ONE,
    infinite: false,
    arrow: false,
    autoplay: true
};
