import React, {useEffect} from "react";
import PropTypes from "prop-types";
import CarServiceHistoryBenefits from "../../shared/car-service-history-benefits";
import CarServiceHistoryBanner from "../car-service-history-banner";
import CarServiceHistorySteps from "../../shared/car-service-history-steps";
import VasDownloadSampleReport from "../../shared/vas-download-sample-report";
import CarServiceHistoryRisks from "../car-service-history-risks";
import CarServiceHistoryTestimonials from "../car-service-history-testimonials";
import styles from "./styles.css";
import CarServiceHistoryFaqs from "../car-service-history-faqs";
import { SERVICE_HISTORY_SAMPLE_REPORT_URL } from "../../../constants/car-service-history-constants";
import VasViewOrders from "../vas-view-orders";
import { ORDER_CATEGORY } from "../../../constants/my-orders-constants";
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";
import { mobileNumberValidator } from "../../../utils/validators/mobile-number-validator";
import { isValidRegistrationNo } from "../../../utils/helpers/is-valid-registration-no";
import useC2bCleverTap from "../../../hooks/use-c2b-clevertap";

const CarServiceHistoryPartner = ({
    setIsSsrConnect,
    updateSSRStatusConnect,
    history,
    mobile,
    setMobileConnect,
    logoutConnect,
    markUnauthorizedConnect,
    setRegNoConnect,
    setBypassTruecallerConnect
}) => {
    useC2bCleverTap("Vehicle_history");

    useEffect(() => {
        setIsSsrConnect(false);
        updateSSRStatusConnect(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkForValidRegistrationNumber = (regNo) => {
        return isValidRegistrationNo(regNo.replace(/\s/g, ""));
    };

    const preFillDetails = ({vrn, phone}) => {
        if (phone) setMobileConnect(phone);
        if (vrn) {
            setRegNoConnect(vrn);
            history.push("/car-service-history-info/");
        }
    };

    useEffect(() => {
        const queryParams = parseLocationSearchParams(history.location.search);
        const { vrn = "", phone = "" } = queryParams;
        setBypassTruecallerConnect(true);
        if (vrn || phone) {
            const isValidMobileNumber = mobileNumberValidator(phone);
            const isValidVrn = checkForValidRegistrationNumber(vrn);
            if (isValidVrn || isValidMobileNumber) {
                if (mobile && phone && Number(mobile) !== Number(phone)) {
                    logoutConnect().then(() => {
                        markUnauthorizedConnect();
                        preFillDetails(queryParams);
                    });
                } else {
                    preFillDetails(queryParams);
                }
            }
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);

    return (
        <React.Fragment>
            <CarServiceHistoryBanner/>
            <div styleName={"styles.wrapper"}>
                <VasDownloadSampleReport
                    fileName={"Service_History_Sample_Report.pdf"}
                    eventCategory={"service_homepage"}
                    url={SERVICE_HISTORY_SAMPLE_REPORT_URL}
                />
                <div styleName={"styles.viewOrdersWrapper"}>
                    <VasViewOrders orderCategory={ORDER_CATEGORY.SERVICE_HISTORY} />
                </div>
                <CarServiceHistoryRisks/>
                <div styleName={"styles.carServiceHistoryBenefits"}>
                    <CarServiceHistoryBenefits/>
                </div>
                <div styleName={"styles.carServiceHistorySteps"}>
                    <CarServiceHistorySteps />
                </div>
            </div>
            <CarServiceHistoryTestimonials slug={"car_service_history"} />
            <div styleName={"styles.faqMainWrapper"}>
                <CarServiceHistoryFaqs/>
            </div>
        </React.Fragment>
    );
};

CarServiceHistoryPartner.propTypes = {
    setIsSsrConnect: PropTypes.func,
    updateSSRStatusConnect: PropTypes.func,
    history: PropTypes.object,
    mobile: PropTypes.string,
    setMobileConnect: PropTypes.func,
    logoutConnect: PropTypes.func,
    markUnauthorizedConnect: PropTypes.func,
    setRegNoConnect: PropTypes.func,
    setBypassTruecallerConnect: PropTypes.func
};

export default CarServiceHistoryPartner;

