import React, {useEffect, Fragment} from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import Shield from "./images/shield.svg";
import Slider from "react-slick";
import { SLIDER_SETTINGS } from "./selector";
import LazyImage from "../lazy-image";
import { NUMBER } from "../../../constants/app-constants";

const CarServiceHistoryRisks = ({
    list = [],
    isDesktop = false,
    genericContent,
    isSsr,
    fetchGalleryContentConnect
}) => {

    const {content = "Risks of not checking car history report"} = genericContent || {};

    useEffect(() => {
        if (!isSsr && list.length === NUMBER.ZERO) fetchGalleryContentConnect("service-history");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSsr]);

    const renderList = () => {
        return list.map(({heading = "", subHeading = "", subHeading2 = "", desktopImage = "", image = ""}, index) =>
            (<div key={`${heading}-${index}`} styleName={"styles.card"}>
                <div styleName={"styles.cardImgWrapper"}>
                    <LazyImage src={isDesktop ? desktopImage : image} alt={heading}/>
                </div>
                <div styleName={"styles.titlebox"}>
                    <LazyImage src={Shield} alt="Shield" width="32px" height="32px" />
                    {heading}
                </div>
                <div styleName={"styles.cardText"}>
                    <h4>{subHeading}</h4>
                    <p>{subHeading2}</p>
                </div>
            </div>)
        );
    };

    const renderLayout = () => {
        return (
            <Slider
                {...{...SLIDER_SETTINGS, ...(isDesktop && { slidesToShow: NUMBER.FOUR, dots: false })}}
            >
                {renderList()}
            </Slider>);
    };

    return (
        <Fragment>
            {list && list.length > NUMBER.ZERO &&
                <div styleName={"styles.cardWrapper"}>
                    <h2>{content}</h2>
                    <div styleName={"styles.historyCard"}>
                        {renderLayout()}
                    </div>
                </div>
            }
        </Fragment>
    );
};

CarServiceHistoryRisks.propTypes = {
    list: PropTypes.array,
    isDesktop: PropTypes.bool,
    genericContent: PropTypes.object,
    isSsr: PropTypes.bool,
    fetchGalleryContentConnect: PropTypes.func
};

export default CarServiceHistoryRisks;
