import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "../../shared/button";
import styles from "./styles.css";
import { isValidRegistrationNo } from "../../../utils/helpers/is-valid-registration-no";
import H2 from "../../shared/h2";
import { NUMBER } from "../../../constants/app-constants";
import { sendCustomGaEvents } from "../../../tracking";
import { vrnEntered, vrnChanged, getReportClicked } from "./tracking";
import InputRegNo from "../../shared/input-reg-no";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";

const CarServiceRegNo = ({
    regNo = "",
    setRegNoConnect,
    history,
    isLoading = false,
    isFullScreenApplicable = false,
    handleClick = () => {}
}) => {
    const [registrationNumber, setRegistrationNumber] = useState(regNo || "");
    const [isError, setIsError] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        if (isFullScreenApplicable) {
            setRegistrationNumber("");
            if (inputRef && inputRef.current) {
                window.setTimeout(() => {
                    inputRef.current.focus();
                }, NUMBER.FIVE_HUNDRED);
            }
        }
    }, [isFullScreenApplicable]);

    const registrationNumberValidator = (v) => {
        return isValidRegistrationNo(v.replace(/\s/g, ""));
    };

    const handleOnChange = (sanitizedValue) => {
        setRegistrationNumber(sanitizedValue.replace(/ /g, ""));
        setIsError(false);
    };

    const onClick = async () => {
        if (registrationNumberValidator(registrationNumber)) {
            setIsError(false);
            setRegNoConnect(registrationNumber);
            if (isFullScreenApplicable) {
                handleClick(registrationNumber);
                await yieldToMain();
                sendCustomGaEvents({
                    ...vrnChanged,
                    eventLabel: registrationNumber
                });
            } else {
                await yieldToMain();
                history.push("/car-service-history-info");
                sendCustomGaEvents({
                    ...vrnEntered,
                    eventLabel: registrationNumber
                });
            }
        } else {
            setIsError(true);
        }
        if (!isFullScreenApplicable) {
            await yieldToMain();
            sendCustomGaEvents({
                ...getReportClicked,
                eventLabel: registrationNumber
            });
        }
    };

    const onFocus = () => {
        if (isError) setIsError(false);
    };

    return (
        <div styleName={`styles.bannerWrapper`}>
            <H2 text="Enter vehicle number" />
            <div styleName={`styles.registrationWrapper`}>
                <InputRegNo
                    placeholder={"DL 01 AB12XX"}
                    value={registrationNumber}
                    onChange={handleOnChange}
                    validator={registrationNumberValidator}
                    errorMessage={"Please enter valid registraton no."}
                    autoCapitalize={"characters"}
                    isError={isError}
                    disableOnChangeError={true}
                    onFocusCallback={onFocus}
                    inputRef={inputRef}
                />
                <Button
                    text={isFullScreenApplicable ? "Update" : "Get Report"}
                    onClick = {onClick}
                    isLoading={isLoading}
                />
            </div>

        </div>
    );
};

CarServiceRegNo.propTypes = {
    isLoading: PropTypes.bool,
    isFullScreenApplicable: PropTypes.bool,
    history: PropTypes.object,
    regNo: PropTypes.string,
    handleClick: PropTypes.func,
    setRegNoConnect: PropTypes.func
};

export default CarServiceRegNo;
