import React from "react";
import PropTypes from "prop-types";
import HomepageFaq from "../homepage-faq/component";

const CarServiceHistoryFaqs = ({
    history,
    faqList,
    fetchFaqListConnect
}) => {
    return (
        <HomepageFaq
            history={history}
            faqList={faqList}
            fetchFAQConnect={fetchFaqListConnect}
            faqSlug={"service-history"}
            isCtaVisible={false}
        />
    );
};

CarServiceHistoryFaqs.propTypes = {
    history: PropTypes.object,
    faqList: PropTypes.array,
    fetchFaqListConnect: PropTypes.func
};

export default CarServiceHistoryFaqs;
