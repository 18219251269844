import ServiceHistory from "./images/detailed-service-history.svg";
import OdometerReading from "./images/odometer-reading.svg";
import CarPart from "./images/car-parts.svg";
import ReportProblem from "./images/report-problem.svg";

export const OPTIONS_LIST = [
    {
        img: ServiceHistory,
        title: "Vehicle details",
        subtitle: "Access a comprehensive report of the car’s like make, model, year, insurance, loan, etc"
    },
    {
        img: CarPart,
        title: "Car parts",
        subtitle: "Know if any parts were replaced, repaired or repainted"
    },
    {
        img: OdometerReading,
        title: "Odometer reading",
        subtitle: "Get the accurate mileage of the vehicle, including tampering issues, if any"
    },
    {
        img: ReportProblem,
        title: "Accident history & information",
        subtitle: "Get details about any accidents, damages, or flooding incidents"
    }

];
