import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CarServiceHistoryRisks from "../../shared/car-service-history-risks";
import { fetchGalleryContent } from "../car-service-history-info/actions";

const mapStateToProps = ({
    carServiceHistoryInfo: { galleryContent: list, isSsr },
    meta: { genericContent }
}) => ({
    list,
    isSsr,
    genericContent
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchGalleryContentConnect: fetchGalleryContent
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CarServiceHistoryRisks);

