/* eslint-disable complexity */
import React, { useState, useEffect } from "react";
import H2 from "../../shared/h2/component";
import styles from "./styles.css";
// import ArrowIcon from "../../shared/arrow";
import PropTypes from "prop-types";
import { sendCustomGaEvents } from "../../../tracking";
import { buyCarFaqClick } from "./tracking";
import likeBlue from "./images/like-blue.svg";
import dislikeGrey from "./images/dislike-grey.svg";
import likeGrey from "./images/like-grey.svg";
import dislikeBlue from "./images/dislike-blue.svg";
import Highlighter from "react-highlight-words";
import SchemaValuationFaq from "../../shared/schema-valuation-faq";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";

const PilotFaq = ({
    faqs,
    headingComponent = H2,
    secondaryHeading = false,
    heading = "Frequently Asked Questions",
    secondaryHeadingText = "",
    pushEvent = true,
    clickHandler = () => { },
    defaultClosed,
    isRevampedDesign = false,
    customEvents = false,
    customEventValue = {},
    customEventLabel = "",
    isPostInspectionFaq = false,
    isHelpful = false,
    searchText = "",
    isHomePageFetchingFAQ = false,
    attachSchema = false
}) => {
    const [isOpen, setIsOpen] = useState({
        1: true
    });
    const [isLiked, setIsLiked] = useState({});
    const [isDisliked, setIsDisliked] = useState({});

    useEffect(() => {
        if (defaultClosed) setIsOpen({});
    }, [defaultClosed]);

    const onClick = async (id) => {
        setIsOpen((state) => ({ [id]: !state[id] }));
        await yieldToMain();
        if (pushEvent) {
            sendCustomGaEvents(buyCarFaqClick);
        }

        if (customEvents) {
            sendCustomGaEvents({
                ...customEventValue,
                eventAction: faqs[id - 1].faqAction,
                eventLabel: customEventLabel
            });
        }
    };

    const onClickLike = (id, interactionType) => {
        if (interactionType === "like") {
            setIsLiked((state) => ({ ...state, [id]: true }));
            setIsDisliked((state) => ({ ...state, [id]: false }));
        } else {
            setIsDisliked((state) => ({ ...state, [id]: true }));
            setIsLiked((state) => ({ ...state, [id]: false }));
        }
    };

    const Heading = headingComponent;

    return (
        <div styleName={"styles.faq"}>
            <Heading isNewFont={isPostInspectionFaq} text={heading} />
            {secondaryHeading &&
                <h3 styleName={"styles.subTitle"}>{secondaryHeadingText}</h3>
            }
            {
                faqs.map(({ id, question, answer }) => {
                    // const rightArrow = -180;
                    // const downArrow = 0;

                    return (
                        <div key={id} styleName={`styles.wrap ${isRevampedDesign ? "styles.wrapRevamped" : ""} ${isPostInspectionFaq ? "styles.postInspectionWrap" : ""}`} className={`${isOpen[id] ? "activeWrap" : ""}`}>
                            <h3 styleName={`styles.title ${isOpen[id] ? "styles.active" : ""}`}
                                onClick={() => onClick(id)}
                            >{searchText !== "" ?
                                    <Highlighter
                                        highlightClassName="YourHighlightClass"
                                        searchWords={searchText.split(" ")}
                                        autoEscape={true}
                                        textToHighlight={question}
                                    /> : question}
                                {/* <ArrowIcon black={"BlackArrow"} rotateBy={isOpen[id] ? rightArrow : downArrow} /> */}

                            </h3>
                            {
                                isHomePageFetchingFAQ ? <div className="content" styleName={`${isOpen[id] ? "styles.content" : "styles.contentHide"}`} onClick={clickHandler}>
                                    {searchText !== "" ?
                                        <Highlighter
                                            highlightClassName="YourHighlightClass"
                                            searchWords={searchText.split(" ")}
                                            autoEscape={true}
                                            textToHighlight={answer}
                                        /> : answer}
                                    {isHelpful &&
                                        <div styleName={"styles.helpfulWrap"}>
                                            <label>Helpful?</label>
                                            <span><img onClick={() => onClickLike(id, "like")} src={`${isLiked[id] ? `${likeBlue}` : `${likeGrey}`}`} alt="Yes" />Yes</span>
                                            <span><img onClick={() => onClickLike(id, "dislike")} src={`${isDisliked[id] ? `${dislikeBlue}` : `${dislikeGrey}`}`} alt="No" />No</span>
                                        </div>
                                    }
                                </div>
                                    : <div className="content" styleName={`${isOpen[id] ? "styles.content" : "styles.contentHide"}`} onClick={clickHandler} dangerouslySetInnerHTML={
                                        { __html: answer }
                                    } />
                            }

                        </div>
                    );
                })
            }
            {
                attachSchema &&
                <SchemaValuationFaq faqs={faqs} />
            }
        </div>
    );
};

PilotFaq.propTypes = {
    faqs: PropTypes.array,
    secondaryHeadingText: PropTypes.string,
    secondaryHeading: PropTypes.bool,
    headingComponent: PropTypes.string,
    attachSchema: PropTypes.bool,
    heading: PropTypes.string,
    pushEvent: PropTypes.bool,
    clickHandler: PropTypes.func,
    defaultClosed: PropTypes.bool,
    isRevampedDesign: PropTypes.bool,
    customEvents: PropTypes.bool,
    customEventValue: PropTypes.object,
    customEventLabel: PropTypes.string,
    isPostInspectionFaq: PropTypes.bool,
    isHelpful: PropTypes.bool,
    searchText: PropTypes.string,
    isHomePageFetchingFAQ: PropTypes.bool
};

export default PilotFaq;
