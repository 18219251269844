import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CarServiceRegNo from "./component";
import { setRegNo } from "../car-service-history-info/actions";
import { withRouter } from "react-router";

const mapStateToProps = ({
    carServiceHistoryInfo: {
        regNo
    }
}) => ({
    regNo
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setRegNoConnect: setRegNo
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CarServiceRegNo));
