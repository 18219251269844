import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    SET_REG_NO
    SET_MASKED_PHONE
    SET_IS_ERROR
    SET_SERVICE_HISTORY_CART
    SET_CART_DETAILS
    SET_CART_RESPONSE
    SET_WHATSAPP_CONSENT
    SET_DETAILS
    SET_IS_SSR
    SET_BYPASS_TRUECALLER
    SET_MANUAL_LEAD
`,
    {
        prefix: "mychallans/"
    }
);
