import React from "react";
import styles from "./styles.css";
import {OPTIONS_LIST} from "./selector";
import LazyImage from "../lazy-image";

const CarServiceHistoryBenefits = () => {
    return (
        <div styleName={"styles.carServiceWhatYouGet"}>
            <h2>What would you get in the report</h2>
            <p styleName={"styles.text"}>The vehicle history of a car refers to the record of all the maintenance and repair work that has been performed on the vehicle throughout its life.</p>
            <div styleName={"styles.carServiceWrapper"}>
                {
                    OPTIONS_LIST.map(({img, title, subtitle}, index) => (
                        <div key={index} styleName={"styles.carServiceCard"}>
                            <div styleName={"styles.vehicleTitle"}>
                                <LazyImage src={img} alt={title} width="24px" height="24px" />
                                <h3>{title}</h3>
                            </div>
                            <p>{subtitle}</p>
                        </div>
                    )
                    )}
            </div>
        </div>
    );
};

export default CarServiceHistoryBenefits;

