export const ORDER_CATEGORY = {
    ALL: "all",
    CHALLAN: "challan",
    SERVICE_HISTORY: "service_history",
    CAR_WASH: "carwash",
    CAR_HEALTH_REPORT: "car_health_report",
    PRE_DELIVERY_INSPECTION: "pre_delivery_inspection",
    NEW_CAR_PRE_DELIVERY_INSPECTION: "new_car_pre_delivery_inspection",
    GS_SERVICE_HISTORY: "gs_service_history",
    C24_SERVICE_HISTORY: "C24_SERVICE_HISTORY",
    SELLER_PACKAGE: "seller_package",
    STUDIO_PHOTOS: "car_photoshoot_images"
};
