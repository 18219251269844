import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CarServiceHistoryPartner from "./component";
import { setBypassTruecaller, setIsSsr, setRegNo } from "../car-service-history-info/actions";
import { updateSSRStatus } from "../../shared/meta-tags/actions";
import { withRouter } from "react-router";
import { logoutUser, markUnauthorized, setMobile } from "../../configuration/actions";

const mapStateToProps = ({
    user: { mobile }
}) => ({
    mobile
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setIsSsrConnect: setIsSsr,
    updateSSRStatusConnect: updateSSRStatus,
    setRegNoConnect: setRegNo,
    setMobileConnect: setMobile,
    logoutConnect: logoutUser,
    markUnauthorizedConnect: markUnauthorized,
    setBypassTruecallerConnect: setBypassTruecaller
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CarServiceHistoryPartner));

