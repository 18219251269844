import React from "react";
import styles from "./styles.css";
import {OPTIONS_LIST} from "./selector";
import LazyImage from "../lazy-image";

const CarServiceHistorySteps = () => {
    return (
        <div styleName={"styles.carServiceWhatYouGet"}>
            <h2>How does it work ?</h2>
            <div styleName={"styles.carServiceWrapper"}>
                {
                    OPTIONS_LIST.map(({img, title, text}, index) => (
                        <div key={index} styleName={"styles.carServiceCard"}>
                            <LazyImage src={img} alt={title} width="120px" height="120px"/>
                            <div>
                                <h3>{title}</h3>
                                <p>{text}</p>
                            </div>
                        </div>
                    )
                    )}
            </div>
        </div>
    );
};

export default CarServiceHistorySteps;
