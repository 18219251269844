/* eslint-disable no-unused-vars */
import Types from "./types";
import { LeadServiceJava } from "../../../service/lead-service-java-api";
import { carConsumerService } from "../../../service/car-consumer-gateway-api";
import { NUMBER } from "../../../constants/app-constants";
import parseEChallanPaymentSummary from "../../../utils/parsers/parse-e-challan-payment-summary";
import parseCarServiceHistory from "../../../utils/parsers/parse-car-service-history";
import { WHATSAPP_STAGE } from "../../../constants/app-constants";
import { VehicleService } from "../../../service/vehicle-service";
import faqFormatter from "../../../utils/helpers/faq-formatter";
import getTestimonialsWithRating from "../../../utils/helpers/get-testimonials-with-rating";

const setRegNo = regNo => ({
    type: Types.SET_REG_NO,
    regNo
});

const setMaskedPhone = maskedPhone => ({
    type: Types.SET_MASKED_PHONE,
    maskedPhone
});

const setIsError = isError => ({
    type: Types.SET_IS_ERROR,
    isError
});

const setServiceHistoryCart = serviceHistoryCart => ({
    type: Types.SET_SERVICE_HISTORY_CART,
    serviceHistoryCart
});

const setCartResponse = cartResponse => ({
    type: Types.SET_CART_RESPONSE,
    cartResponse
});

const setCartDetails = cartDetails => ({
    type: Types.SET_CART_DETAILS,
    cartDetails
});

const setWhatsappConsent = whatsappConsent => ({
    type: Types.SET_WHATSAPP_CONSENT,
    whatsappConsent
});

const setDetails = data => ({
    type: Types.SET_DETAILS,
    data
});

const setIsSsr = isSsr => ({
    type: Types.SET_IS_SSR,
    isSsr
});

const setManualLead = manualLead => ({
    type: Types.SET_MANUAL_LEAD,
    manualLead
});

const createProspectLead = (payload, authToken) => dispatch => {
    dispatch(setWhatsappConsent(true));
    return new Promise((resolve, reject) => {
        LeadServiceJava.createProspectLead(payload, authToken)
            .then(response => {
                dispatch(setMaskedPhone(response.data.detail.maskedPhone));
                resolve(response.data.detail);
            })
            .catch(error => {
                reject(error);
            });
    });
};

const getProspectLeadDetails = (token) => dispatch => {
    return new Promise((resolve, reject) => {
        LeadServiceJava.getProspectLeadDetails(token)
            .then(response => {
                const consent = response.data.detail.whatsappConsent;
                dispatch(setRegNo(response.data.detail.vehicleRegNo));
                dispatch(setMaskedPhone(response.data.detail.maskedPhone));
                dispatch(setWhatsappConsent(consent === WHATSAPP_STAGE.STAGE_1 || consent === WHATSAPP_STAGE.STAGE_2));
                resolve(response.data.detail);
            })
            .catch(error => {
                reject(error);
            });
    });
};

const getCarServiceHistory = (token) => dispatch => {
    return new Promise((resolve, reject) => {
        LeadServiceJava.getCarServiceHistory(token)
            .then(response => {
                dispatch(setServiceHistoryCart(parseCarServiceHistory(response.data)));
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
    });
};

const addToCart = (details) => dispatch => {
    return new Promise((resolve, reject) => {
        carConsumerService.addToCart(details)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
};

const getCartDetails = (details) => dispatch => {
    return new Promise((resolve, reject) => {
        carConsumerService.getCartDetails(details)
            .then(response => {
                dispatch(setCartDetails(parseEChallanPaymentSummary(response.data)));
                dispatch(setCartResponse(response.data));
                resolve();
            })
            .catch(error => {
                reject(error);
            });
    });
};

const createOrder = (details) => dispatch => {
    return new Promise((resolve, reject) => {
        carConsumerService.createOrder(details)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
    });
};

const emptyCart = (details) => dispatch => {
    return new Promise((resolve, reject) => {
        carConsumerService.emptyCart(details)
            .then(() => {
                resolve();
            })
            .catch(error => {
                reject(error);
            });
    });
};

const updateProspectLead = (payload) => dispatch => {
    return new Promise((resolve, reject) => {
        LeadServiceJava.updateProspectLead(payload)
            .then(response => {
                resolve(response.data.detail);
            })
            .catch(error => {
                reject(error);
            });
    });
};

const fetchTestimonials = (payload) => dispatch => {
    return new Promise((resolve, reject) => {
        VehicleService.fetchTestimonialsByCategory(payload)
            .then(response => {
                dispatch(setDetails({
                    testimonials: getTestimonialsWithRating(response.data.detail || [])
                }));
                resolve(response.data.detail);
            })
            .catch(error => {
                reject(error);
            });
    });
};

const fetchGalleryContent = (payload) => dispatch => {
    return new Promise((resolve, reject) => {
        VehicleService.getGalleryContent(payload)
            .then(response => {
                dispatch(setDetails({ galleryContent: response.data.detail || [] }));
                resolve(response.data.detail);
            })
            .catch(error => {
                reject(error);
            });
    });
};

const fetchFaqList = (payload) => dispatch => {
    return new Promise((resolve, reject) => {
        VehicleService.fetchFaqs(payload)
            .then(response => {
                dispatch(setDetails({ faqList: faqFormatter(response.data.detail || {}) }));
                resolve(response.data.detail);
            })
            .catch(error => {
                reject(error);
            });
    });
};

const setBypassTruecaller = bypassTruecaller => ({
    type: Types.SET_BYPASS_TRUECALLER,
    bypassTruecaller
});

export {
    setRegNo,
    setIsError,
    createProspectLead,
    getProspectLeadDetails,
    getCarServiceHistory,
    emptyCart,
    getCartDetails,
    addToCart,
    createOrder,
    updateProspectLead,
    setDetails,
    fetchTestimonials,
    setIsSsr,
    fetchGalleryContent,
    fetchFaqList,
    setBypassTruecaller,
    setManualLead
};
