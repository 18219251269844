import Step1 from "./images/step1.png";
import Step2 from "./images/step2.png";
import Step3 from "./images/step3.png";

export const OPTIONS_LIST = [
    {
        img: Step1,
        title: "Enter details",
        text: "Enter your vehicle registration number"
    },
    {
        img: Step2,
        title: "Online payment",
        text: "Pay securely with CARS24"
    },
    {
        img: Step3,
        title: "Get report",
        text: "Get the report within 24 hours"
    }
];
