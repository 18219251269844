import React, { useEffect, useState, Fragment } from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import PilotFaq from "../pilot-faq/component";
import { NUMBER, redirect } from "../../../constants/app-constants";
import faqFormatter from "../../../utils/helpers/faq-formatter";
import PilotFaqV2 from "../pilot-faq-v2";
import { LinkButton } from "@cars24/turbo-web/lib/link-button";
import { ArrowIcon } from "@cars24/turbo-web/lib/icons/arrow-icon";

const HomePageFaq = ({
    isSellOnline,
    fetchFAQConnect,
    faqSlug = "",
    faqList = [],
    isCtaVisible = true,
    isDlsCompliant = false
}) => {
    const [faqs, setFaqs] = useState(faqList || []);

    const getSlugName = () => {
        if (faqSlug) return faqSlug;
        if (isSellOnline) return "seller-common-homepage";
        return "homepage-nonsellonline";
    };

    useEffect(() => {
        if (faqList && faqList.length === NUMBER.ZERO) {
            fetchFAQConnect({ slug: getSlugName() })
                .then(response => {
                    setFaqs(faqFormatter(response));
                })
                .catch(() => { });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            {faqs && faqs.length > 0 &&
            isDlsCompliant ?
                <div styleName={"styles.pilotFaqV2"}>
                    <PilotFaqV2 list={faqs} attachSchema />
                    <LinkButton
                        label={"Read More"}
                        suffixIcon={<ArrowIcon size="14px" />}
                        size="medium"
                        variant="brand"
                        href={`${redirect.CONSUMER_WEB_IN}/faq/`}
                    />
                </div> :
                <div  styleName={"styles.faqWrapper"}>
                    <PilotFaq
                        heading="Frequently asked questions"
                        faqs={faqs}
                        isRevampedDesign={true}
                        attachSchema={true}
                    />
                    {
                        isCtaVisible &&
                    <span styleName={"styles.seeAllFaq"} onClick={() => window.location.href(`${redirect.CONSUMER_WEB_IN}/faq/`)}>SEE ALL FAQs</span>
                    }
                </div>
            }
        </Fragment>
    );
};

HomePageFaq.propTypes = {
    isSellOnline: PropTypes.bool,
    fetchFAQConnect: PropTypes.func,
    faqSlug: PropTypes.string,
    faqList: PropTypes.array,
    isCtaVisible: PropTypes.bool,
    isDlsCompliant: PropTypes.bool
};

export default HomePageFaq;
