import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CarServiceHistoryFaqs from "./component";
import { withRouter } from "react-router";
import { fetchFaqList } from "../car-service-history-info/actions";

const mapStateToProps = ({
    carServiceHistoryInfo: { faqList }
}) => ({
    faqList
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchFaqListConnect: fetchFaqList
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CarServiceHistoryFaqs));

