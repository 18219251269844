import React, { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import CarouselBottomArrow from "../carousel-bottom-arrow";
import styles from "./styles.css";
import { NUMBER } from "../../../constants/app-constants";
import UserProfile from "./images/user-profile.svg";
import LazyImage from "../lazy-image";
import { SLIDER_SETTINGS, STARS_MAPPING } from "./selector";

const TestimonialCarousel = ({
    heading = "Our happy customers",
    list = [],
    slidesToShow = NUMBER.ONE_POINT_THREE,
    isSsr,
    fetchTestimonialsConnect = () => { },
    slug = "",
    isRevamped = false,
    dots = false,
    arrows = false
}) => {

    useEffect(() => {
        if (!isSsr && list.length === NUMBER.ZERO) fetchTestimonialsConnect(slug);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSsr]);

    const getStars = (stars = []) => {
        return (
            stars.map((item, index) =>
                <LazyImage key={`${item}-${index}`} src={STARS_MAPPING[item]} alt="star" />
            )
        );
    };

    return (
        <Fragment>
            {list && list.length > NUMBER.ZERO &&
                <div styleName={`styles.testimonialWrapper ${isRevamped ? "styles.revampedTestimonialWrapper" : ""}`} className="cvh">
                    <h2>{heading}</h2>
                    <div styleName={"styles.carouselWrap"}>
                        <CarouselBottomArrow
                            settings={{ ...SLIDER_SETTINGS, slidesToShow, dots, arrows }}>
                            {list.map(({ fullName = "", cityName = "", testimonialTitle = "", starsList = [], imgUrl = "" }, index) =>
                                (isRevamped ?
                                    (<div key={`${testimonialTitle}-${index}`} styleName={"styles.testimonialCarouselCard"}>
                                        <div>
                                            <p>{testimonialTitle}</p>
                                            <div styleName={"styles.starsContainer"}>{getStars(starsList)}</div>
                                        </div>
                                        <div styleName={"styles.testimonialUser"}>
                                            <div styleName={"styles.userInfo"}>
                                                <h3>{fullName}</h3>
                                                <span>{cityName}</span>
                                            </div>
                                            <LazyImage src={imgUrl || UserProfile} alt="profile" />
                                        </div>
                                    </div>) : (<div key={`${testimonialTitle}-${index}`} styleName={"styles.testimonialCarouselCard"}>
                                        <div styleName={"styles.testimonialUser"}>
                                            <LazyImage src={imgUrl || UserProfile} alt="profile" />
                                            <div styleName={"styles.userInfo"}>
                                                <h3>{fullName}</h3>
                                                <span>{cityName}</span>
                                                <div styleName={"styles.starsContainer"}>{getStars(starsList)}</div>
                                            </div>
                                        </div>
                                        <p>{testimonialTitle}</p>
                                    </div>))
                            )}
                        </CarouselBottomArrow>
                    </div>
                </div>
            }
        </Fragment>
    );
};

TestimonialCarousel.propTypes = {
    heading: PropTypes.string,
    list: PropTypes.array,
    slidesToShow: PropTypes.string,
    isSsr: PropTypes.bool,
    fetchTestimonialsConnect: PropTypes.func,
    slug: PropTypes.string,
    isRevamped: PropTypes.bool,
    arrows: PropTypes.bool,
    dots: PropTypes.bool
};

export default TestimonialCarousel;
