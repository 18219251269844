import React, { useState } from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import DownloadStrip from "./images/download-icon.svg";
import Download from "./images/download.svg";
import Completed from "./images/completed.svg";
import { NUMBER } from "../../../constants/app-constants";
import LazyImage from "../lazy-image";
import { sendCustomGaEvents } from "../../../tracking";
import { downloadReportClicked } from "./tracking";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";

const VasDownloadSampleReport = ({
    eventCategory = "",
    fileName = "",
    url = ""
}) => {

    const [showToast, setShowToast] = useState(false);

    const handleClick = async () => {
        await yieldToMain();
        if (!showToast) {
            setShowToast(true);
            window.setTimeout(() => {
                setShowToast(false);
            }, NUMBER.TWO_THOUSAND);
        }
        sendCustomGaEvents({
            ...downloadReportClicked,
            ...(eventCategory && { eventCategory })
        });
    };
    return (
        <a
            target="_blank"
            download={fileName}
            href={url}
            onClick={handleClick}
        >
            <div styleName={"styles.downloadStrip"}>
                <div styleName={"styles.downloadText"}>
                    <LazyImage src={DownloadStrip} alt={"download icon"} />
                    Download sample report
                </div>
                <div styleName={"styles.downloadicon"}>
                    <LazyImage src={Download} alt={"download Icon"} />
                </div>
            </div>
            {showToast &&
                <div styleName={"styles.downlaodInfo"}>
                    <LazyImage src={Completed} alt={"Completed"} />
                    Sample Report Downloaded
                </div>
            }
        </a>
    );
};

VasDownloadSampleReport.propTypes = {
    eventCategory: PropTypes.string,
    fileName: PropTypes.string,
    url: PropTypes.string
};

export default VasDownloadSampleReport;

