import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import ArrowIcon from "../../shared/arrow";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import { NUMBER } from "../../../constants/app-constants";
import { ORDER_CATEGORY } from "../../../constants/my-orders-constants";

const VasViewOrders = ({
    history,
    orderCategory = ORDER_CATEGORY.CHALLAN,
    secureToken,
    isLoggedIn,
    getOrderByCategoryConnect
}) => {

    const [count, setCount] = useState(NUMBER.ZERO);

    const getOrderByCategory = () => {
        getOrderByCategoryConnect({
            authToken: secureToken,
            filterCriteria: { orderCategory }
        }).then((response) => setCount((response || {}).count));
    };

    useEffect(() => {
        if (isLoggedIn) getOrderByCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const redirectToOrders = async () => {
        await yieldToMain();
        history.push(`/my-orders/?serviceName=${orderCategory}`);
    };

    return (
        <React.Fragment>
            {(count > NUMBER.ZERO && isLoggedIn) && <span styleName={"styles.viewOrdersCta"} onClick={redirectToOrders}>
                View existing orders <ArrowIcon />
            </span>}
        </React.Fragment>
    );
};

VasViewOrders.propTypes = {
    history: PropTypes.object,
    orderCategory: PropTypes.string,
    secureToken: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    getOrderByCategoryConnect: PropTypes.func
};

export default VasViewOrders;
