import React, {useEffect} from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import CarServiceRegNo from "../car-service-reg-no";

const CarServiceHistoryBanner = ({
    mainHeading = "Check car History Report & buy with confidence",
    genericContent,
    isSSR,
    fetchDataConnect
}) => {

    const {
        heading = "@ ₹199 onwards!",
        subHeading = "Find hidden issues with any car"} = genericContent || {};

    useEffect(() => {
        if (!isSSR && !mainHeading) {
            const payload = {
                cmsuri: "service-history",
                expand: "seo_content"
            };
            fetchDataConnect(payload);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSSR]);

    return (
        <div styleName={"styles.serviceHistoryBanner"}>
            <div styleName={"styles.serviceWrapper"}>
                {mainHeading && <h1>{mainHeading}</h1>}
            </div>
            <div styleName={"styles.subText"}>
                {subHeading && <p>{subHeading}</p>}
                {heading && <h4>{heading}</h4>}
            </div>
            <div styleName={"styles.serviceRegNo"}>
                <CarServiceRegNo/>
            </div>
        </div>
    );
};

CarServiceHistoryBanner.propTypes = {
    mainHeading: PropTypes.string,
    genericContent: PropTypes.object,
    isSSR: PropTypes.bool,
    fetchDataConnect: PropTypes.func
};

export default CarServiceHistoryBanner;
