/* eslint-disable no-unused-vars */
import { carConsumerService } from "../../../service/car-consumer-gateway-api";

const getOrderByCategory = (payload) => dispatch => {
    return new Promise((resolve, reject) => {
        carConsumerService.getOrderByCategory(payload)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
    });
};

export {
    getOrderByCategory
};
