export const vrnEntered = {
    event: "vrn_entered",
    eventCategory: "service_homepage",
    eventAction: "VRN_entered"
};

export const vrnChanged = {
    event: "vrn_changed",
    eventCategory: "service_homepage",
    eventAction: "VRN_changed"
};

export const getReportClicked = {
    event: "get_report_cta_clicked",
    eventCategory: "service_homepage",
    eventAction: "GetReport_clicked"
};
