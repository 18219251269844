import { STARS } from "../../components/shared/testimonial-carousel/selector";
import { NUMBER } from "../../constants/app-constants";

const getStarsList = (value) => {
    let sanitizedValue = Number(value);
    if (value && !isNaN(sanitizedValue)) {
        const arr = [];
        for (let i = 0; i < NUMBER.FIVE; i++) {
            if (sanitizedValue > NUMBER.ZERO && sanitizedValue < NUMBER.ONE) arr.push(STARS.HALF);
            else if (sanitizedValue > NUMBER.ZERO) arr.push(STARS.FILLED);
            else arr.push(STARS.EMPTY);
            sanitizedValue--;
        }
        return arr;
    } else {
        return [];
    }
};

export default (list = []) => {
    return list.map((item) => {
        const {rating = ""} = item || {};
        return {
            ...item,
            starsList: getStarsList(rating)
        };
    });
};
