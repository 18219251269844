const getItemDetails = (item) => {
    const { additionalCharges, itemDetails, ...rest } = item;
    const { agentFee } = additionalCharges || {};
    return {
        charges: agentFee,
        additionalCharges,
        ...(itemDetails || {}),
        ...rest
    };
};

const getBifurcatedDetails = (list, isBifurcation) => {
    if (!isBifurcation) {
        const data = list.map(getItemDetails);
        return { list: data };
    }
    const onlineChallans = [];
    const offlineChallans = [];
    const vasOrder = [];
    list.forEach((item) => {
        const obj = getItemDetails(item);
        if ((obj.challanState  === "Online" || (obj.product && obj.product.challanState)  === "Online")) {
            onlineChallans.push(obj);
        } else if (obj.category === "challan") {
            offlineChallans.push(obj);
        } else {
            vasOrder.push(obj);
        }
    });
    const regNo = list && list.length && list[0] && list[0].product && list[0].product.registrationNumber;
    const isAllSettled = list.filter(elem => elem.status === "FULFILLED").length === list.length;
    const orderCategory = list && list.length && list[0] && list[0].category;
    return { regNo, isAllSettled, onlineChallans, offlineChallans, totalChallans: list.length, orderCategory, vasOrder };
};

export default (details = {}, isBifurcation = false) => {
    const {
        cartItems = [],
        orderItems = [],
        grossAmount,
        platformFee,
        netAmount,
        orderNumber = "",
        transactionId = "",
        orderDate = "",
        createdAt = "",
        status = "",
        userMaskedPhoneNumber = "",
        rtoRegisteredPhoneMasked = "",
        challanCallingVirtualNumber = "",
        invoiceAvailable = false
    } = details || {};
    const data = getBifurcatedDetails([...cartItems, ...orderItems], isBifurcation);
    const orderItemStatusLabelMap = {};
    orderItems.forEach(({status: orderItemStatus = ""}) => {
        if (orderItemStatusLabelMap[orderItemStatus]) orderItemStatusLabelMap[orderItemStatus] += 1;
        else orderItemStatusLabelMap[orderItemStatus] = 1;
    });
    const orderItemStatusLabel = Object.keys(orderItemStatusLabelMap).map((key) => {
        return `${key}_${orderItemStatusLabelMap[key]}`;
    }).join(" | ");
    return {
        ...data,
        grossAmount,
        platformFee,
        netAmount,
        orderNumber,
        transactionId,
        orderDate,
        createdAt,
        status,
        userMaskedPhoneNumber,
        rtoRegisteredPhoneMasked,
        challanCallingVirtualNumber,
        orderItemStatusLabel,
        invoiceAvailable
    };
};
