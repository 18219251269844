import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import VasViewOrders from "./component";
import { withRouter } from "react-router";
import { getOrderByCategory } from "./actions";

const mapStateToProps = ({
    user: { secureToken, isLoggedIn }
}) => ({
    secureToken,
    isLoggedIn
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getOrderByCategoryConnect: getOrderByCategory
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VasViewOrders));
