export default (details = {}) => {
    const { item, vehicleRegistrationDetails, sampleReport = "" } = details || {};
    const {
        rc_owner_name: ownerName = "",
        rc_owner_name_masked: maskedOwnerName = "",
        year,
        ds_details: dsDetails = [],
        brand,
        model
    } = vehicleRegistrationDetails || {};
    const {  make_display: brandName = "" } = brand || {};
    const {  model_display: modelName = "" } = model || {};
    const { year: vehicleYear = ""} = year || {};
    const { variant_display_name: variant = "" } = dsDetails && dsDetails[0] && dsDetails[0].variant || {};

    return {
        ...(item || {}),
        ownerName: ownerName || maskedOwnerName,
        brandName,
        modelName,
        year,
        vehicleYear,
        variant,
        sampleReport
    };
};
