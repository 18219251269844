import React from "react";
import ErrorHandler from "../../../components/shared/with-error-handler";
import { Helmet } from "react-helmet-async";
import withReducer from "../../../components/shared/with-reducer";
import withLayout from "../../../components/mobile/layout/component";
import CarServiceHistoryPartner from "../../../components/mobile/car-service-history-partner";
import injectable from "./injectable";

const CarServiceHistoryPartnerContainer = () => {
    return (
        <ErrorHandler>
            <Helmet>
                <meta name="ROBOTS" content="NOINDEX,NOFOLLOW" />
            </Helmet>
            <CarServiceHistoryPartner />
        </ErrorHandler>
    );
};

export default withReducer(injectable)(() => ({}), dispatch => ({dispatch}))(
    withLayout(
        CarServiceHistoryPartnerContainer,
        false,
        false,
    )
);
