import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TestimonialCarousel from "../../shared/testimonial-carousel";
import { fetchTestimonials } from "../car-service-history-info/actions";

const mapStateToProps = ({
    carServiceHistoryInfo: { testimonials: list, isSsr }
}) => ({
    list,
    isSsr
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchTestimonialsConnect: fetchTestimonials
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TestimonialCarousel);

