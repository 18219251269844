import { NUMBER } from "../../../constants/app-constants";
import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    regNo: "",
    maskedPhone: "",
    serviceHistoryCart: {},
    cartDetails: {},
    cartResponse: {},
    isError: false,
    whatsappConsent: true,
    testimonials: [],
    faqList: [],
    galleryContent: [],
    isSsr: true,
    bypassTruecaller: false,
    manualLead: {
        brand: {},
        model: {},
        activeAccordionStepIndex: NUMBER.ZERO,
        token: null
    }
};

export const setRegNo = (state = INITIAL_STATE, { regNo = "" }) => {
    return { ...state, regNo};
};

export const setMaskedPhone = (state = INITIAL_STATE, { maskedPhone = "" }) => {
    return { ...state, maskedPhone};
};

export const setIsError = (state = INITIAL_STATE, { isError }) => {
    return { ...state, isError};
};

export const setServiceHistoryCart = (state = INITIAL_STATE, { serviceHistoryCart = {} }) => {
    return { ...state, serviceHistoryCart};
};

export const setCartDetails = (state = INITIAL_STATE, { cartDetails = {} }) => {
    return {...state, cartDetails};
};

export const setCartResponse = (state = INITIAL_STATE, { cartResponse = {} }) => {
    return {...state, cartResponse};
};

export const setWhatsappConsent = (state = INITIAL_STATE, {whatsappConsent}) => {
    return { ...state, whatsappConsent};
};

export const setDetails = (state = INITIAL_STATE, {data}) => {
    return {...state, ...data};
};

export const setIsSsr = (state = INITIAL_STATE, {isSsr}) => {
    return {...state, isSsr};
};

export const setBypassTruecaller = (state = INITIAL_STATE, {bypassTruecaller}) => {
    return { ...state, bypassTruecaller};
};

export const setManualLead = (state = INITIAL_STATE, { manualLead = {} }) => {
    return { ...state, manualLead};
};

export const HANDLERS = {
    [Types.SET_REG_NO]: setRegNo,
    [Types.SET_MASKED_PHONE]: setMaskedPhone,
    [Types.SET_IS_ERROR]: setIsError,
    [Types.SET_SERVICE_HISTORY_CART]: setServiceHistoryCart,
    [Types.SET_CART_DETAILS]: setCartDetails,
    [Types.SET_CART_RESPONSE]: setCartResponse,
    [Types.SET_WHATSAPP_CONSENT]: setWhatsappConsent,
    [Types.SET_DETAILS]: setDetails,
    [Types.SET_IS_SSR]: setIsSsr,
    [Types.SET_BYPASS_TRUECALLER]: setBypassTruecaller,
    [Types.SET_MANUAL_LEAD]: setManualLead
};

export default createReducer(INITIAL_STATE, HANDLERS);
