export default (data, businessEntity = "sell") => {
    const idx = (data["business-order"] || []).findIndex(element => element.includes(businessEntity));

    if (idx >= 0) {
        const type = data["business-order"][idx];
        const res = data[type];

        const list = [];

        if (res) {
            res.forEach((item) => {
                item.content.forEach((faq) => {
                    const itemIndex = list.length + 1;
                    list.push({
                        ...faq,
                        id: itemIndex
                    });
                });
            });
        }

        return list;
    }
    return [];
};
