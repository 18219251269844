import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CarServiceHistoryBanner from "./component";
import { fetchData } from "../../shared/meta-tags/actions";

const mapStateToProps = ({
    meta: {
        mainHeading,
        isSSR,
        genericContent
    }
}) => ({
    genericContent,
    mainHeading,
    isSSR
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchDataConnect: fetchData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CarServiceHistoryBanner);

